<template>
<div>
    <div class="tyc-header " >
        <div class="tyc-header-container">
            <a href="/" class="tyc-header-logo">
                <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" alt="">
                <span>经济运行平台</span>
            </a>
            <div class="tyc-header-right">
                <div class="tyc-header-nav">     
                    <div class="tyc-header-nav-item">
                        <el-link :underline="false" href="/#/login">登录</el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="user-login">
        <div class="head-bg">
        </div>
        <div class="login-area">
            <div class="app-login_wrapper app-login-new">
                <div class="right-c" style="padding:20px 30px;" >
                    <div style="text-align: center;color: #333;font-size: 18px; margin:20px 0 30px 0;">
                        <span>找回密码</span>
                    </div>
                    <el-form :model="forgetPwdForm"  status-icon  :rules="forgetPwdFormRules" ref="forgetPwdFormRef" label-width="0">
                        <el-form-item label-width="0" prop="tel">
                            <el-input v-model="forgetPwdForm.tel" placeholder="手机号" autocomplete="off"  >
                                <template #prepend>
                                    <span >中国 +86</span>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label-width="0" prop="verifyCode" style="border: 1px solid #DCDFE6;">
                            <el-input v-model="forgetPwdForm.verifyCode"  placeholder="6位数字" oninput="value=value.replace(/[^0-9.]/g,'')"  class="input-verifycode" />
                            <div class="vcode-area fr">
                                <el-link :underline="false" href="#" @click.prevent='sendVerifyCodeFun' v-if='isSend' class="vcode-btn">获取验证码</el-link>
                                <el-link :underline="false" disabled v-if='!isSend' class="vcode-btn">{{sendMsg}}</el-link>
                            </div>
                        </el-form-item>
                        <el-form-item label-width='0'  prop="password">
                            <el-input
                                type="password" placeholder="新密码（6-16位，包含大小写字母、数字、特殊字符）"
                                v-model="forgetPwdForm.password"
                                autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label-width='0'  prop="checkpass">
                        <el-input
                            type="password" placeholder="确认密码"
                            v-model="forgetPwdForm.checkpass"
                            autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label-width='0'>
                            <el-button  style="width: 100%;font-weight: bold;font-size: 16px;" type="primary" @click="submitForm()">确定</el-button>
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-link :underline="false" type="primary" href="/#/login" style="vertical-align: text-top;">
                        <i class="iconfont ec-icon-arrow-left" style="margin-right: -8px;font-size: 700;font-weight: 700;font-size: 1.2rem;vertical-align: text-bottom;"></i>
                        返回登录</el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { ref } from '@vue/reactivity'
import {sendVerifyCode} from "../http/loginApi";  
import {setPassword} from '../http/homeApi'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router';
export default {
    name:'Register',
    components:{
  },
    setup() {
        const router=useRouter();
        let forgetPwdForm=ref({
            tel:'',
            verifyCode:'',
            isAgree:false,//是否同意《隐私政策》和《用户协议》
            password:'',
            checkpass:'',
        })
        let forgetPwdFormRef = ref(null); 
        //验证两次密码是否一致
        let validatePass2 = (rule, value, callback) => { 
            if (value === '') {
                callback(new Error('请再次输入密码')) 
            } else if (value !== forgetPwdForm.value.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        };
        let validatePass=(rule, value, callback)=>{
             //var reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)(?![0-9a-zA-Z]+$)(?![0-9\\W]+$)(?![a-zA-Z\\W]+$)[0-9A-Za-z\\W]{6,18}$", "g");
             var reg =new RegExp("^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{6,16}$", "g");

             if (value === '') {
                callback(new Error('请输入密码')) 
            } else if (!reg.test(value)) {
                callback(new Error('包含大小写字母、数字、特殊字符'))
            } else {
                callback()
            } 
        }
        //注册提交验证
        let forgetPwdFormRules = {
            tel: [
                { required: true, message: "请输入手机号", trigger: "blur" },
                { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
            ],
            verifyCode: [
                { required: true, message: "请输入验证码", trigger: "blur" },
                { min: 6, max: 6, message: "长度为6字符", trigger: "blur" },
            ],
            password: [
                { required: true, message: "请输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass , trigger: "blur" } 
            ],
            checkpass: [
                { required: true, message: "请第二次输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass2 , trigger: "blur" } 
            ]
        };
        
        let sendMsg=ref('发送验证码')
        let isSend=ref(true)
        //获取验证码
        const sendVerifyCodeFun=()=>{
            let obj = forgetPwdFormRef.value;  
            console.log(obj);
            obj.validateField(['tel'],(isValid) => {
                console.log(isValid);
                if(!isValid){
                    console.log(isValid);
                    sendVerifyCode(forgetPwdForm.value.tel,1)
                    .then((res) => {
                        if ( res.code == 20000) {
                            //Message.success(res.data.msg)
                            isSend.value = false;
                            let timer = 60;
                            sendMsg.value = timer + "s后可重发";
                            const timeFun = setInterval(() => {
                                --timer;
                                sendMsg.value = timer + "s后可重发";
                                if (timer == 0) {
                                    isSend.value = true;
                                    sendMsg.value = "获取短信验证码";
                                    clearInterval(timeFun);
                                }
                            }, 1000);
                        }
                    });
                }
            });
        }
        //找回密码
        const submitForm= ()=>{
            let obj = forgetPwdFormRef.value;  
            obj.validate((isValid) => {
                if(isValid){
                    let params={
                        tel:forgetPwdForm.value.tel,
                        verifyCode:forgetPwdForm.value.verifyCode,
                        password:forgetPwdForm.value.password,
                        checkPassword:forgetPwdForm.value.checkpass
                    }
                    setPassword(params).then((res)=>{
                        if(res.code==20000){
                            router.push('/login');
                        }
                        else{
                            ElMessage.error({
                                message: res.message, 
                            });  
                        }
                    });
                }
            });
           
        }
        //加载执行
        //如果用户已登录，直接跳转到首页
        if(localStorage.getItem('currentUser')){//当前用户信息
            router.push('/');
        }
        return{
            forgetPwdForm,
            forgetPwdFormRef,
            forgetPwdFormRules,
            sendMsg,
            isSend,
            sendVerifyCodeFun,
            submitForm,
        }
    },
}
</script>
<style  lang="scss" scoped>
@import '@/styles/variables.scss';
.t1_top_layer {
    background: rgba(14, 35, 60, 0.5);
    border-bottom: 1px solid rgba(63, 148, 253, 0.3);
}
.tl_header_T0v0W{
    min-width: 1080px;
    max-width: 1180px;
    margin: auto;
    height: 80px;
    padding:0 20px;
}
.tl_link_wrap_EyH-F{
    float: right;
    margin-top: 30px;
    font-size: 14px;
}
.reg_wrap {
    margin: 40px auto;
    width: 1000px;
    padding: 0 10px;
    min-height: 500px;
}
.reg_pass{
    padding-left: 60px;
    position: relative;
    margin-left: 120px;
}
.reg_pass_msg{
    font-size: 18px;
    color: rgba(255,216,148);
}
.reg_pass_icon{
    font-size: 32px;
    color: #67C23A;
    position: absolute;
    left: 0;
}
.vcode-area {
    height: 40px;
    width: 100px;
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    top: 0px;
}
.input-verifycode{
    width: 70%;
    ::v-deep .el-input__inner{
        border: none;
    }
}
.vcode-btn {
    top: 50%;
    transform: translateY(-50%);
    width: 86px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    right: 4px;
    cursor: pointer;
    min-width: 88px;
    display: inline-block;
}
.vcode-btn:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 28px;
    background: #d8d8d8;
    right: 90px;
    top: 6px;
    height: 25px;
}
// ::v-deep .el-link.el-link--default {
//     color: $specialtext-color;
// }
// ::v-deep .el-link.el-link--default:hover {
//     color:darken($specialtext-color,10%);
// }
// ::v-deep .el-form-item__label{
//     color:#c0c4cc;

// }
</style>
